// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';
// ----------------------------------------------------------------------
function jwtDecode(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split('')
        .map(function (c) { return "%".concat("00".concat(c.charCodeAt(0).toString(16)).slice(-2)); })
        .join(''));
    return JSON.parse(jsonPayload);
}
// ----------------------------------------------------------------------
export var isValidToken = function (accessToken) {
    if (!accessToken) {
        return false;
    }
    var decoded = jwtDecode(accessToken);
    var currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};
// ----------------------------------------------------------------------
export var tokenExpired = function (exp) {
    // eslint-disable-next-line prefer-const
    var expiredTimer;
    var currentTime = Date.now();
    // Test token expires after 10s
    // const timeLeft = currentTime + 10000 - currentTime; // ~10s
    var timeLeft = exp * 1000 - currentTime;
    clearTimeout(expiredTimer);
    expiredTimer = setTimeout(function () {
        alert('Token expired');
        localStorage.removeItem('accessToken');
        window.location.href = PATH_AUTH.login;
    }, timeLeft);
};
// ----------------------------------------------------------------------
export var setSession = function (accessToken) {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = "Bearer ".concat(accessToken);
        // This function below will handle when token is expired
        var exp = jwtDecode(accessToken).exp; // ~3 days by minimals server
        tokenExpired(exp);
    }
    else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};
